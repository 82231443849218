/**
 * 
 */
export const apiPath = {
  //#region User
  USER_LOGIN: 'v1/users/login',
  SEND_EMAIL_VALIDATION: 'v1/users/sendValidationEmail',
  USER_EMAIL_VALIDATION: 'v1/users/validation',
  USER_INFO: (time: number) => `v1/policy/person?timestamp=${time}`,
  USER_IDENTITY_STATUS: (time: number) => `v1/dossier/status?timestamp=${time}`,
  // USER_INFO: `v1/policy/person`,
  CHANGE_PASSWORD: 'v1/users/changePassword',
  EMAIL_RECOVERY_PASSWORD: 'v1/users/sendRestorePasswordEmail',
  RESTORE_PASSWORD: 'v1/users/restorePassword',
  //#endregion

  //#region Policy
  POLICY_LIST: (time: number) => `v1/products?timestamp=${time}`,
  POLICY_DOCUMENT_TERMS: (policyNumber: string) => `v1/policy/legal_docs/${policyNumber}/user_legal_terms`,
  POLICY_DOCUMENT_TERMS_SUMARY: (policyNumber: string) => `v1/policy/legal_docs/${policyNumber}/user_legal_terms_summary`,
  POLICY_DOCUMENT_COVER: (policyId: string) => `/v1/documents/COVER/${policyId}`,
  POLICY_DOCUMENT_RECEIPT: (policyId: string) => `/v1/documents/RECEIPT/${policyId}`,
  POLICY_ENDORSEMENTS: (policyId: string) => `/v1/endorses/${policyId}`,
  POLICY_ALLOW_NEW_POLICY: `v1/person/isAllowedNewPolicy`,
  POLICY_HAS_PICTURES: (policyId: string) => `/v1/wp/policy/${policyId}/vehicle/has-photos`,
  POLICY_PAYMENT_METHOD: (policyId: string) => `/v1/policy/payment-method/${policyId}`,
  POLICY_PAYMENT_HISTORY: (policyNumber: string) => `/v1/policy/payments/${policyNumber}`,
  IS_VECHILE_PICTURE: `v1/is-vehicle-image`,
  VEHICLE_UPLOAD_PICTURES: (policyId: string) => `/v1/wp/policy/${policyId}/vehicle/upload-photos`,
  PICTURES_TOKEN: '/v1/wp/upload-photos-token',
  CANCELLATION_REASONS: '/v1/catalog/policy/cancellationReasons',
  POLICY_CANCELLATION: '/v1/policy/cancel_temporal',
	CHECK_TEMPORAL_CANCELLATION: (policyId: string) => `/v1/policy/cancel_temporal/${policyId}`,
	CHECK_NEXT_PAYMENT: (policyNumber: string) => `/v1/policy/next_payment/${policyNumber}`,
  //#endregion

  //#region Policy Managment
  VEHICLE_BRAND: 'v1/catalog/vehicle/brand',
  VEHICLE_MODEL: (brand: string) => `${apiPath.VEHICLE_BRAND}/${brand}/type`,
  VEHICLE_YEAR: (brand: string, model: string) => `${apiPath.VEHICLE_BRAND}/${brand}/type/${model}/model`,
  VEHICLE_VERSION: (brand: string, model: string, year: string) => `${apiPath.VEHICLE_BRAND}/${brand}/type/${model}/model/${year}/version`,
  CREATE_VEHICLE: 'v1/policy/vehicle',
  CREATE_DRIVER: '/v1/policy/driver',
  VERIFY_VEHICLE_VIN: (vin: string) => `v1/policy/vehicle/verify/${vin}`,
  VERIFY_PERSON_RFC: (rfc: string) => `v1/policy/person/rfc/${rfc}/isAvailable`,
  VERIFY_EMAIL: (email: string) => `v1/policy/person/email/${email}/isAvailable`,
  VERIFY_POSTAL_CODE: (postalCode: string) => `v1/catalog/pld/postalEntities?code=${postalCode}`,
	GET_ECONOMIC_ACTIVITIES: 'v1/catalog/pld/economicActivities',
	GET_OWN_BUSINESS_ACTIVITIES: 'v1/catalog/pld/economicActivities/complex',
  QUOTATION: 'v1/quotation',
  POLICY_REQUEST: 'v1/policy/request',
  POLICY_PAYMENT: 'v1/policy/paymentorder',
	POLICY_MONTHLY_PAYMENT: 'v1/policy/pay',
  VALIDATE_INVOICE: '/v1/invoices/validate',
  INVOICE_DETAILS: '/v1/invoices/detail',
  INVOICES_INFO: '/v1/users/invoice-information',
  SAVE_INVOICE_INFO: '/v1/users/invoice',
  CREATE_INVOICE: 'v1/invoices',
	POLICY_TAX_REGIMES: '/v1/users/tax-regimes',
  POLICY_TAX_REGIMENS_BY_TYPE: (type: string) => `/v1/catalog/sat/tax-regimes/${type}`,
	POLICY_INVOICE_DATA: (personId: string) => `/v1/policy/invoice/${personId}`,
	POLICY_SET_INVOICE: '/v1/policy/invoice',
	POLICY_AUTOMATIC_RENEWAL: '/v2/policy/automaticrenewal',
  //#endregion

  //#region 
  OTP_GENERATE_CODE: '/v1/otp/generate',
	OTP_GENERATE_UUID_CODE: '/v2/otp/generate',
  OTP_VERIFY_CODE: '/v1/otp/verificate',
	OTP_VERIFY_UUID_CODE: '/v2/otp/verificate',
  //#endregion

  //#region Truora
  CREATE_TRUORA_TOKEN: 'v1/dossier/ine',
  //#endregion

  //#region Picture upload
  CLAIM_UPLOAD_PICTURE: (id: string, requirement: string) => `/v2/claims/${id}/${requirement}/upload`,
	CLAIM_UPDATE_PICTURE: (id: string, requirement: string, hash: string) => `/v2/claims/${id}/${requirement}/update/${hash}`,
  //#endregion

  //#region Damage Payment
  DAMAGE_PAYMENT: '/v2/damagepayments',
  GET_RELATED_DAMAGE_PAYMENTS: (policyNumber: string) => `${apiPath.DAMAGE_PAYMENT}/?policy_number=${policyNumber}`,
	GET_DAMAGE_PAYMENT_BY_ID: (damageClaim: string) => `${apiPath.DAMAGE_PAYMENT}/${damageClaim}`,
	CLAIMS_UPDATE_USER: (damagePaymentID: string, damagePaymentRequirement: string) => `/v2/claims/${damagePaymentID}/${damagePaymentRequirement}`,
	DAMAGE_PAYMENT_INTERNAL: '/v2/internal/damagepayments',
	CLAIMS_UPDATE_VEHICLE: (damagePaymentID: string, damagePaymentRequirement: string) => `/v2/claims/${damagePaymentID}/${damagePaymentRequirement}`,
  CLAIM_PAYMENT_NEGOTATION: (damagePaymentId: string, proposalId: string) => `v2/negotiation/${proposalId}/damagepayments/${damagePaymentId}`,
  //#endregion

	//#region Agreed Payment
	AGREED_PAYMENT: '/v2/agreedpayment',
	GET_AGREED_PAYMENT_BY_ID: (damageClaim: string) => `${apiPath.AGREED_PAYMENT}/${damageClaim}`,
	AGREED_PAYMENT_NEGOTATION: (damagePaymentId: string, proposalId: string) => `v2/negotiation/${proposalId}/agreedpayment/${damagePaymentId}`,
	//#endregion

  //#region Referrals
  REWARDS_HISTORY: '/v2/rewards/record',
  REFERRALS_REWARDS: '/v2/rewards',
  EXCHANGE_REWARD: '/v2/rewards/record',
  //#endregion

	//#region Quoter
	QUOTER_REGISTER_USER: '/v1/users/register',
	//#endregion

  //#region Claims
	CLAIMS_STATUS: '/public/status',
  CLAIM_STATUS_TYPE: (type: string) => `/public/status?type=${type}`,
  CLAIM_STATUS_CAUSE: `/status-cause-type`,
  CLAIM_STATES: `/public/address/states?countryId=1`,
  CLAIM_STATES_BY_POSTAL_CODE: (postalCode: string) => `/public/address?postalCode=${postalCode}`,
  CLAIM_PERSON_INFO: (id?: string, key?: string) => `/people/form${id && key? `?id=${id}&key=${key}` : ''}`,
  CLAIM_ADJUSTER_INFO: (id?: string, key?: string) => `/adjuster/form${id && key? `?id=${id}&key=${key}` : ''}`,
  CLAIM_PICTURES: `/photo`,
  CLAIM_DOCUMENTS: `/document`,
	CLAIMS_CATEGORIES_LIST: (serviceType: string) => `public/status?serviceType=${serviceType}&category=true`,
	CLAIMS_PROVIDERS: `provider`,
  CREATE_EDUA_QR: 'amis/fol',
  VINCULATE_EDUA_QR: 'amis/vinculate',
  SYNC_EDUA_DATA: 'amis/synchronize',
  GET_SYNCHRONIZED_DATA: 'amis/thirdinformation',
  REFOL_CLAIM: 'amis/refol',
  ISSUE_DIGITAL_VOUCHER: 'amis/issuedigitalvoucher',
  RECEIVE_DIGITAL_VOUCHER: 'amis/receivedigitalvoucher',
  GENERATE_AMIS_ORDER: 'amis/orders',
  END_AMIS_ATTENTION: 'amis/endattention',
  GENERATE_ADJUSTER_INFORM: 'amis/adjusterinform',

  //#endregion

  //#region Documents Signature
  DOCUMENT_SIGNATURE_CLAIM_FORMS: (id?: string, key?: string) => `/docs/form${id && key? `?id=${id}&key=${key}` : ''}`,
  //#endregion
}
